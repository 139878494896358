import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import axios from 'axios'

import { useAuth } from 'hooks/useAuth'
import Login from './components/Login'
import Menu from './components/Layout/Menu'
import PageLoader from './components/Loader/PageLoader'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'


axios.defaults.withCredentials = true;

const Home = lazy(() => import('./views/Home'))
const AdminHome = lazy(() => import('./views/Admin/Home'))
const AdminUsers = lazy(() => import('./views/Admin/Users'))
const AdminEnvStorage = lazy(() => import('./views/Admin/EnvStorage'))
const EvoHome = lazy(() => import('./views/Evo/Home'))
const EvoBot = lazy(() => import('./views/Evo/Bot'))
const EvoScripts = lazy(() => import('./views/Evo/Scripts'))
const EvoAudios = lazy(() => import('./views/Evo/Audios'))
const EvoProxies = lazy(() => import('./views/Evo/Proxies'))
const StatsHome = lazy(() => import('./views/Stats'))
const NotFound = lazy(() => import('./views/NotFound'))


const App: React.FC = () => {
	const auth = useAuth();

	function getCurrentView() {
		if (auth.status == 1) {
			return <PageLoader loading={true} />
		} else if (auth.status == 2) {
			return <Login/> 
		} else if (auth.status == 3) {
			return (
				<Menu>         
					<SuspenseWithChunkError fallback={<PageLoader loading={true} />}>
						<div className="h-full">
							<Routes>
								<Route path="/" element={<Home />} />
								<Route path="/admin" element={<AdminHome />} />
								<Route path="/admin/users" element={<AdminUsers />} />
								<Route path="/admin/envStorage" element={<AdminEnvStorage />} />
								<Route path="/evo" element={<EvoHome />} />
								<Route path="/evo/bot" element={<EvoBot />} />
								<Route path="/evo/scripts" element={<EvoScripts />} />
								<Route path="/evo/audios" element={<EvoAudios />} />
								<Route path="/evo/proxies" element={<EvoProxies />} />
								<Route path="/stats" element={<StatsHome />} />
								<Route path="*" element={<NotFound />} />
							</Routes>
						</div>
					</SuspenseWithChunkError>
				</Menu>
			)
		}
	}

	return (
		getCurrentView()
	);
}

export default React.memo(App)