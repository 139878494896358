import React from 'react'
import { useAuth } from 'hooks/useAuth'
import { NavLink, useLocation } from 'react-router-dom'

interface MenuProps {
	children: React.ReactNode;
}


const Menu: React.FC<MenuProps> = ({ children }) => {
	const auth = useAuth();
	const location = useLocation();


	const isHome = location.pathname == '/';
	const activeApp =
		location.pathname.startsWith('/evo') ? 'evo' : null ||
		location.pathname.startsWith('/admin') ? 'admin' : null;


	function renderMenu() {
		switch (activeApp) {
			case 'admin':
				return (
					<>
						<div className='w-80 flex flex-col pt-4 gap-2'>
							<NavLink to="/admin" end className={({ isActive }) => 'border px-4 py-1.5 font-semibold text-base ' + (isActive ? 'bg-purple-700 text-black border-purple-700' : 'text-white bg-gray-900 border-gray-900 hover:bg-purple-700 hover:border-purple-700 hover:text-black')}>
								Home
							</NavLink>
							<NavLink to="/admin/users" className={({ isActive }) => 'border px-4 py-1.5 font-semibold text-base ' + (isActive ? 'bg-purple-700 text-black border-purple-700' : 'text-white bg-gray-900 border-gray-900 hover:bg-purple-700 hover:border-purple-700 hover:text-black')}>
								Users
							</NavLink>
							<NavLink to="/admin/envstorage" className={({ isActive }) => 'border px-4 py-1.5 font-semibold text-base ' + (isActive ? 'bg-purple-700 text-black border-purple-700' : 'text-white bg-gray-900 border-gray-900 hover:bg-purple-700 hover:border-purple-700 hover:text-black')}>
								EnvStorage
							</NavLink>
						</div>
						<div className='bg-gray-700/80 w-[1px] rounded-full mx-8 my-2'></div>
					</>
				)
			case 'evo':
				return (
					<>
						<div className='w-80 flex flex-col pt-4 gap-2'>
							<NavLink to="/evo" end className={({ isActive }) => 'border px-4 py-1.5 font-semibold text-base ' + (isActive ? 'bg-purple-700 text-black border-purple-700' : 'text-white bg-gray-900 border-gray-900 hover:bg-purple-700 hover:border-purple-700 hover:text-black')}>
								Home
							</NavLink>
							<NavLink to="/evo/scripts" className={({ isActive }) => 'border px-4 py-1.5 font-semibold text-base ' + (isActive ? 'bg-purple-700 text-black border-purple-700' : 'text-white bg-gray-900 border-gray-900 hover:bg-purple-700 hover:border-purple-700 hover:text-black')}>
								Scripts
							</NavLink>
							<NavLink to="/evo/audios" className={({ isActive }) => 'border px-4 py-1.5 font-semibold text-base ' + (isActive ? 'bg-purple-700 text-black border-purple-700' : 'text-white bg-gray-900 border-gray-900 hover:bg-purple-700 hover:border-purple-700 hover:text-black')}>
								Audios
							</NavLink>
							<NavLink to="/evo/proxies" className={({ isActive }) => 'border px-4 py-1.5 font-semibold text-base ' + (isActive ? 'bg-purple-700 text-black border-purple-700' : 'text-white bg-gray-900 border-gray-900 hover:bg-purple-700 hover:border-purple-700 hover:text-black')}>
								Proxies
							</NavLink>
							<NavLink to="/evo/bot" className={({ isActive }) => 'border px-4 py-1.5 font-semibold text-base ' + (isActive ? 'bg-purple-700 text-black border-purple-700' : 'text-white bg-gray-900 border-gray-900 hover:bg-purple-700 hover:border-purple-700 hover:text-black')}>
								Discord Bot
							</NavLink>
						</div>
						<div className='bg-gray-700/80 w-[1px] rounded-full mx-8 my-2'></div>
					</>
				)
			default:
				return null;
		}
	}

	return (
		<div className="animate bg-[#000]">
			<div className={`relative m-auto h-screen max-w-[102rem]`}>
				<div className='flex flex-col h-screen'>

					{/* Header */}
					<div className='min-h-[4rem] flex flex-row items-center mx-8 gap-10'>
						<div className='gap-1 justify-center text-[0.5rem] font-black text-purple-700 leading-tight select-none'>
							<pre>_______|‾‾| __  ______ ______</pre>
							<pre>\_  __ \  |/ / /  ___//  ___/</pre>
							<pre> |  | \/    {'<'}  \___ \ \___ \ </pre>
							<pre> |__|  |__|_ \/____  {'>'}____  {'>'}</pre>
							<pre>            \/     \/     \/ </pre>	
						</div>

						<div className='flex flex-row w-full justify-between'>
							{/* top menu */}
							<div className='flex flex-row gap-3'>
								<NavLink to="/" className={({ isActive }) => 'border px-4 py-1 font-semibold text-sm ' + (isActive ? 'bg-purple-700 text-black border-purple-700' : 'text-white bg-gray-900 border-gray-900 hover:bg-purple-700 hover:border-purple-700 hover:text-black')}>home</NavLink>
								<NavLink to="/admin" className={({ isActive }) => 'border px-4 py-1 font-semibold text-sm ' + (isActive ? 'bg-purple-700 text-black border-purple-700' : 'text-white bg-gray-900 border-gray-900 hover:bg-purple-700 hover:border-purple-700 hover:text-black')}>admin</NavLink>
								<NavLink to="/evo" className={({ isActive }) => 'border px-4 py-1 font-semibold text-sm ' + (isActive ? 'bg-purple-700 text-black border-purple-700' : 'text-white bg-gray-900 border-gray-900 hover:bg-purple-700 hover:border-purple-700 hover:text-black')}>evo.labs</NavLink>
								<NavLink to="/stats" className={({ isActive }) => 'border px-4 py-1 font-semibold text-sm ' + (isActive ? 'bg-purple-700 text-black border-purple-700' : 'text-white bg-gray-900 border-gray-900 hover:bg-purple-700 hover:border-purple-700 hover:text-black')}>stats/perf</NavLink>
							</div>

							{/* logout */}
							<button className="border px-4 py-1 font-semibold hover:bg-red-700 text-white hover:text-black text-sm border-red-700" onClick={auth.logout}>logout</button>
						</div>
					</div>

					{/* Content */}
					<div className='flex flex-row min-h-[calc(100%-5.8rem)] mx-8'>
						{!isHome ? ( renderMenu() ) : null}

						<div className='w-full overflow-y-auto'>
							{children}
						</div>
					</div>

					{/* Footer */}
					<div className='min-h-[1.8rem] flex-row gap-2 flex items-center justify-center text-center text-xs text-gray-200 w-full border-t border-gray-700/80 text-purple-700'>
						{'> rkss.to'} v{process.env.REACT_APP_VERSION}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Menu


